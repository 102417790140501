<template>
  <div :style='sizeBasedStyles'>
      <div class='px-2 lg:px-0 py-4 lg:py-8'>
        <h1 class='mb-2 lg:mb-4 text-xl lg:text-2xl font-semibold uppercase'>Booking & Payment Conditions</h1>
          <ol class='list-decimal list-inside text-xs lg:text-base'>
            <li class=''>The below rates are per room per night.</li>
            <li class=''>As rooms will be assigned on a first-come, first-served basis, reserving early is recommended.</li>
            <li class=''>Cancellation and refund policies are in accordance with the rules of hotel.</li>
            <li class=''>Rates are based on single occupancy per room. Double occupancy rates vary according to each hotel, so please confirm before check-in.</li>
            <li class=''>All personal charges (i.e. room service, phone calls, the mini bar, etc.) are the participant’s responsibility.</li>
            <li class=''>The below rates only apply to ICoLA2022 with APSAVD participants.</li>
          </ol>
      </div>
      <div class='px-2 lg:px-0 py-4 lg:py-8'>
        <h1 class='mb-2 lg:mb-4 text-xl lg:text-2xl font-semibold uppercase'>Available Congress Hotels</h1>
        <div class='overflow-x-auto'>
          <table class='min-w-full'>
            <thead>
              <tr>
                <th class='whitespace-nowrap p-2 uppercase text-left border-b'>Star Rating</th>
                <th class='whitespace-nowrap p-2 uppercase text-left border-b'>Hotel Name</th>
                <th class='whitespace-nowrap p-2 uppercase text-left border-b'>Distance From Venue</th>
                <th class='whitespace-nowrap p-2 uppercase text-left border-b'>Room Type</th>
                <th class='whitespace-nowrap p-2 uppercase text-left border-b'>Room Rate Per Night<span style='font-weight:bold;color:var(--eventMainColor);'>*</span></th>
                <th class='whitespace-nowrap p-2 uppercase text-left border-b'>Reservation Form</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class='p-2 border-b text-red-500 font-semibold'>★★★★★</td>
                <td class='p-2 border-b '>
                  <a href='https://www.hilton.com/en/hotels/selcici-conrad-seoul/' 
                    target='_blank' 
                    class=''>Conrad Seoul</a></td>
                <td class='p-2 border-b '>0 min <span class='opacity-60 uppercase text-xs'>(venue)</span></td>
                <td class='p-2 border-b '>Single Room</td>
                <td class='p-2 border-b '>Aprox KRW 250,000</td>
                <td class='p-2 border-b '>
                  <a class='whitespace-nowrap rounded-full inline-block bg-gray-50 px-4 py-1 text-xs'>Reservation Form Download</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class='text-sm py-2'>
          <span style='font-weight:bold;color:var(--eventMainColor);'>*</span> Rates are subject to change without notice.
        </div>
        <div class='my-4 lg:my-8 flex flex-col lg:flex-row lg:gap-x-4'>
          <img 
            class='block w-full h-40 lg:w-72 lg:h-64 flex-grow-0 flex-shrink-0'
            src='https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/icola2023/hotel_conrad.png' />
          <div class='flex-grow'>
            <h2 class='text-lg lg:text-2xl font-semibold my-2 lg:my-0'>Conrad Seoul</h2>
            <table class='w-full text-sm lg:text-base'>
              <tr>
                <th class='p-2 border-b border-t text-left w-1/5 bg-gray-100'>Check In</th>
                <td class='p-2 border-b border-t '>16:00</td>
                <th class='p-2 border-b border-t text-left w-1/5 bg-gray-100'>Check In</th>
                <td class='p-2 border-b border-t '>16:00</td>
              </tr>
              <tr>
                <th class='p-2 border-b border-t text-left w-1/5 bg-gray-100'>Address</th>
                <td class='p-2 border-b border-t ' colspan='3'>10 Gukjegeumyung-ro Yeouido Yeongdeungpo-gu Seoul, 07326, South Korea</td>
              </tr>
              <tr>
                <th class='p-2 border-b border-t text-left w-1/5 bg-gray-100'>Tel</th>
                <td class='p-2 border-b border-t '>+82-2-6137-7000</td>
                <th class='p-2 border-b border-t text-left w-1/5 bg-gray-100'>Fax</th>
                <td class='p-2 border-b border-t '>+82-2-6137-7001</td>
              </tr>
              <tr>
                <th class='p-2 border-b border-t text-left w-1/5 bg-gray-100'>Email</th>
                <td class='p-2 border-b border-t ' colspan='3'><a href='mailto:Conrad_Seoul@conradhotels.com'>Conrad_Seoul@conradhotels.com</a></td>
              </tr>
              <tr>
                <th class='p-2 border-b border-t text-left w-1/5 bg-gray-100'>Website</th>
                <td class='p-2 border-b border-t ' colspan='3'><a href='https://www.hilton.com/en/hotels/selcici-conrad-seoul/'>https://www.hilton.com/en/hotels/selcici-conrad-seoul/</a></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'HtmlPageTest',
  props: {
    htmlContent: {
      type: String,
      default: '',
    },
    mobileStyles: {
      type: String,
      default: '',
    },
    desktopStyles: {
      type: String,
      default: '',
    },
  },
  computed: {
    sizeBasedStyles () {
      let mediaQuery = window.matchMedia('(min-width: 1024px)') // this is the lg taliwind size
      return (mediaQuery.matches) ? this.desktopStyles : this.mobileStyles
    },
  },
}
</script>
